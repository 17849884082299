<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="supplier" />
    <router-view />
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";

export default {
  components: { ProDocumentBreadcrumbs },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.updateBreadcrumbs(to);
    next();
  },
  methods: {
    updateBreadcrumbs(route) {
      /** @type {[{name: string, icon?: string, to?: {name: string}}]} */
      const arr = [
        {
          name: "menu.Supplier",
          icon: "PROSmart-AccountEdit",
        },
      ];

      if (route.name !== "Supplier") {
        arr.push({ name: route.meta.title });
      }
      this.breadcrumbs = arr;
    },
  },
  created() {
    this.updateBreadcrumbs(this.$route);
  },
};
</script>
